import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { prestationsData } from '../../utils/constant'
import { Fade } from "react-awesome-reveal";
import PagesTransition from '../../Components/PagesTransition';
import client from '../../client';

const Prestation = ({ prestations }) => {
    const { id } = useParams()
    const [prestation, setPrestation] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        client.fetch(`*[_type == "prestations" && slug.current == "${id}"][0] {
            ...,
            content {
            ...,
            cover { asset -> { _id, url } } 
            } 
            }`).then((data) => {
            setPrestation(data.content)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <section className='prestation'>
            {
                prestation &&
                <article>
                    <Fade direction="up" triggerOnce={true}>
                        <h1>{prestation?.title}</h1>
                    </Fade>
                    {
                        prestation?.prestations?.map((paragraph, key) => (
                            <div className='content' key={key}>
                                <Fade direction="up" delay={100} fraction={0} triggerOnce={true}>
                                    <h2>{paragraph?.title}</h2>
                                </Fade>
                                <Fade direction="up" cascade delay={200} fraction={0} triggerOnce={true} >
                                    <div className="paragraph" >
                                        {
                                            paragraph?.items?.map((presta, idx) => (
                                                <div className="pres" key={idx}>
                                                    <div className="text">
                                                        {presta?.description?.split("\n").map((t, key) => <p key={key}>{t}</p>)}
                                                    </div>
                                                    <div className="prices">
                                                        {presta?.prix?.split("\n").map((t, key) => <p key={key}>{t}</p>)}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Fade>
                            </div>

                        ))
                    }
                </article>
            }
            <PagesTransition />
        </section>
    )
}

export default Prestation